import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { StaticImage } from "gatsby-plugin-image"
import "swiper/swiper-bundle.min.css"
import "./style.scss"

const Applets = () => {
  return (
    <div
      css={{
        backgroundColor: "#efeff3",
      }}
    >
      <div className="container">
        <div className="applets m80-m30">
          <div className="title">
            <h3>公众号/小程序开发</h3>
            <h4>OFFICIAL ACCOUNT / MINI PROGRAM DEVELOPMENT</h4>
          </div>
          <Swiper autoPlay={true} centeredSlides={true} loop slidesPerView={3}>
            <SwiperSlide>
              <div className="item">
                <StaticImage
                  css={{
                    margin: "0 auto ",
                  }}
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/微信商城-大.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="微信商城"
                  title="微信商城"
                />
                <h4>微信商城</h4>
                <p>基于微信支付，微信登录等能力的电子商务商城</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <StaticImage
                  css={{
                    margin: "0 auto ",
                  }}
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/小程序-大.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="小程序"
                  title="小程序"
                />
                <h4>小程序</h4>
                <p>免安装、易推广,拥有丰富组件及API能力</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <StaticImage
                  css={{
                    margin: "0 auto",
                  }}
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/微信公众号-大.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="微信公众号"
                  title="微信公众号"
                />
                <h4>微信公众号</h4>
                <p>
                  用于微信开发能力结合原生技术开发用于微信公众号 搭配的用户系统
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Applets
