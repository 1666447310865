import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"

const Programme = () => {
  return (
    <div
      css={{
        backgroundColor: "#efeff3",
      }}
    >
      <div className="container">
        <div className="programme  m80-m30">
          <div className="title">
            <h3>技术方案</h3>
            <h4>ECHNICAL SOLUTIONS</h4>
          </div>
          <div className="row">
            <div className="col-4 lf">
              <div>
                <StaticImage
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/插画-技术支持.png"
                  quality={95}
                  width={270}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="技术支持"
                  title="技术支持"
                />
                <h4 className="d-block d-md-none">技术支持</h4>
              </div>
              <div>
                <StaticImage
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/插画-技术方案.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="技术方案"
                  title="技术方案"
                />
                <h4 className="d-block d-md-none">技术方案</h4>
              </div>
              <div>
                <StaticImage
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/插画-seo.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="seo"
                  title="seo"
                />
                <h4 className="d-block d-md-none">SEO支持</h4>
              </div>
            </div>
            <div className="col-2  mid">
              <div className="soild"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <div className="col-6 col-md-6 rg">
              <div className="item">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <b>01</b>
                  </div>
                  <div className="col-12 col-md-9">
                    <h3>技术支持</h3>
                    <p>遇到web开发相关的疑难杂症可以向我们提出咨询</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <b>02</b>
                  </div>
                  <div className="col-12 col-md-9">
                    <h3>技术方案</h3>
                    <p>根据您的想法和需求，选择最适合项目的技术方案</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <b>03</b>
                  </div>
                  <div className="col-12 col-md-9">
                    <h3>SEO支持</h3>
                    <p>代码SEO优化建议，数据自动主动提交搜索引擎工具开发</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Programme
