import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.scss"

const Mobile = () => {
  return (
    <div
      css={{
        backgroundColor: "#fff",
      }}
    >
      <div className="container">
        <div className="mobile m80-m30">
          <div className="title">
            <h3>移动端/APP开发</h3>
            <h4>MOBILE / APP DEVELOPMENT</h4>
          </div>
          <div className="row">
            <div className="col">
              <div className="item">
                <StaticImage
                  css={{
                    margin: "0 auto",
                  }}
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/bg-app开发.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="app开发"
                  title="app开发"
                />
                <div className="hover_t">
                  <div className="move">
                    <h3>APP开发</h3>
                    <p>IOS和安卓两大主流平台的移动APP客户端开发</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="item">
                <StaticImage
                  css={{
                    margin: "0 auto",
                  }}
                  loading="lazy"
                  layout="constrained"
                  placeholder="blurred"
                  src="../../assets/images/bg-手机网站.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="手机网站"
                  title="手机网站"
                />
                <div className="hover_t">
                  <div className="move">
                    <h3>手机网站</h3>
                    <p>可以在手机浏览器、微信、独立APP等各种环境浏览的网站</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mobile
