import React from "react"
import Layout from "../components/layouts/layout"
import { StaticImage } from "gatsby-plugin-image"
import Web from "../components/service/web"
import Applets from "../components/service/applets"
import Mobile from "../components/service/mobile"
import Programme from "../components/service/programme"

import SEO from "../components/seo"

const Service = () => (
  <Layout>
    <SEO title="服务项目" description="服务项目" />
    <div>
      <StaticImage
        className="d-none d-md-block"
        loading="lazy"
        layout="fullWidth"
        placeholder="blurred"
        src="../assets/images/banner-服务项目.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="服务项目"
        title="服务项目"
      />
      <StaticImage
        className="d-block d-md-none"
        loading="lazy"
        layout="fullWidth"
        placeholder="blurred"
        src="../assets/images/ydd-banner-服务项目.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="服务项目"
        title="服务项目"
      />
    </div>
    <Web />
    <Applets />
    <Mobile />
    <Programme />
  </Layout>
)

export default Service
